/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "This is my second blog post! Isn't it ", React.createElement(_components.em, null, "great"), "?"), "\n", React.createElement(_components.p, null, "Some of my ", React.createElement(_components.strong, null, "favorite"), " things are:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Petting dogs"), "\n", React.createElement(_components.li, null, "Singing"), "\n", React.createElement(_components.li, null, "Eating potato-based foods"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
