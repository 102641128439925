import GATSBY_COMPILED_MDX from "/opt/build/repo/blogs/post-number-two.mdx";
import React, {useState} from "react";
import {graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {StyledBlog} from "../../components/styles/BlogStyles";
import GlobalStyles from "../../components/styles/Global";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Contact from "../../components/Contact";
import Credits from "../../components/Credits";
import {Seo} from "../../components/seo";
const BlogPost = ({data, children}) => {
  const [showCredits, setShowCredits] = useState(false);
  let featuredImg = getImage(data.mdx.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData);
  return React.createElement("main", null, React.createElement(GlobalStyles), React.createElement(Navbar), React.createElement(StyledBlog, null, React.createElement("div", {
    className: "title-container"
  }, React.createElement("h1", {
    className: "blog-title"
  }, data.mdx.frontmatter.title), React.createElement("h3", {
    className: "blog-subheading"
  }, data.mdx.frontmatter.date, " -", " ", Math.ceil(data.mdx.frontmatter.wordCount / 200), " min. read")), featuredImg && React.createElement("div", {
    className: "image-container"
  }, React.createElement(GatsbyImage, {
    className: "featured-image",
    image: featuredImg,
    alt: `${data.mdx.frontmatter.title} featured image`
  }), React.createElement("span", {
    className: "image-credit"
  }, " Image by:", " ", React.createElement("a", {
    className: "image-credit-link",
    href: data.mdx.frontmatter.imageUrl
  }, data.mdx.frontmatter.photographer))), React.createElement("div", {
    className: "blog-body"
  }, children)), React.createElement(Footer, {
    setShowCredits: setShowCredits
  }), React.createElement(Contact), showCredits && React.createElement(Credits, {
    setShowCredits: setShowCredits
  }));
};
export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "dddd DD MMMM YYYY")
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
        imageUrl
        photographer
        wordCount
      }
    }
  }
`;
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({data}) => React.createElement(Seo, {
  title: `Corbin Cargil - Blogs`
});
